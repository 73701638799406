<template>
  <div>
  <!-- 暂停收货 -->
  <el-dialog title="暂停收货" :close-on-click-modal="false" :visible.sync="ShopShow" width="600px" @close="stopResetForm('ruleForm')">
      <div >
        <p style="color: #ff687b;margin-bottom: 10px;">暂停收货后，华为厂商侧将不再自动完结收货</p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label="请选择暂停收货原因" label-width="150px" style="margin:0 0 10px 0" prop="pauseCauseId" >
            <el-radio-group v-model="ruleForm.pauseCauseId" @change="rensonChange">
              <el-radio :label="1" >门店发错货</el-radio>
              <el-radio :label="2" >申请追责</el-radio>
              <el-radio :label="3" >物流丢失</el-radio>
              <el-radio :label="4" >其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否需要回寄" v-if="ruleForm.pauseCauseId===1" label-width="108px" prop="isSendBack" style="margin:0 0 20px 0">
            <el-radio-group v-model="ruleForm.isSendBack">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="回寄物流单号" v-if="ruleForm.isSendBack===true&&ruleForm.pauseCauseId===1" label-width="108px" prop="backExpressNo" style="margin:0 0 10px 0">
            <el-input v-model="ruleForm.backExpressNo" maxlength="50" placeholder="请输入物流单号"></el-input>
          </el-form-item>
          <div v-if="ruleForm.pauseCauseId!==1">
            <el-form-item :label="ruleForm.pauseCauseId===4?'外部备注':'外部备注（非必填）'" style="margin:0" :required="ruleForm.pauseCauseId===4">
              <span style="color: #ff687b">(说明：备注内容将提交给厂商，请注意表述)</span>
            </el-form-item>
            <el-form-item label="" :prop="ruleForm.pauseCauseId===4?'pauseRemark':''" style="margin:0 0 10px 0">
              <el-input type="textarea" maxlength="200" placeholder="请输入外部备注内容，最多200字" v-model.trim="ruleForm.pauseRemark"></el-input>
            </el-form-item>
          </div>
          <div v-if="ruleForm.pauseCauseId===3 || ruleForm.pauseCauseId===2">
            <el-form-item label="内部备注（非必填，内部查看）" prop=""  style="margin:0">
            </el-form-item>
            <el-form-item label="" prop="pauseNote" style="margin:0 0 20px 0">
              <el-input type="textarea" maxlength="200" placeholder="请输入内部备注内容，最多200字" v-model="ruleForm.pauseNote"></el-input>
            </el-form-item>
          </div>
          <div v-if="ruleForm.isSendBack===false&&ruleForm.pauseCauseId===1">
            <div style="color: #666666;">
              <span style="color: #ff687b;margin-right: 5px">*</span><span>请填写以下信息</span>
            </div>
            <p style="font-size: 12px;color: #ff687b;margin:5px 0 10px 10px;">说明:请使用实收旧机的串号查询对应的订单号，若无法查询到订单号，需线下与渠道沟通</p>
            <el-form-item label="实收机器订单号" prop="actualOrderNo" label-width="120px"  style="margin:0 0 20px 0">
              <el-input v-model="ruleForm.actualOrderNo" maxlength="50" placeholder="请填写收到机器实际对应的外部订单号"></el-input>
            </el-form-item> 
            <el-form-item label="实收机器串号" prop="actualImei" label-width="110px"  style="margin:0">
              <el-input v-model="ruleForm.actualImei" maxlength="50" placeholder="请填写收到机器的实际串号"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stopResetForm('ruleForm')">关 闭</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  <!-- 操作提示 -->
  <el-dialog title="操作提示" :visible.sync="traceLogShow" width="400px">
      <div  style="text-align: center;margin:10px 0 20px 0;">
        是否确认提交暂停收货
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button  @click="traceLogShow = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="sureSbmitResult"
          >确 定</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: '',
  mixins: [],
  components: {},
  props: {
    stopShopShow:{
      type: Boolean,
      default: false
    },
    stopShopdata:{
      type: Object,
      default: ()=>{}
    }
  },
  data () {
    return {
      traceLogShow:false,
      ShopShow:false,
      ruleForm: {
          backExpressNo: '',//回寄物流单号
          pauseNote:'',//	暂停收货备注
          actualImei:"",//实收机器串号
          actualOrderNo: '',//实收机器订单号
          pauseCauseId: '',//暂停收货原因id（1：门店发错货，2：申请追责，3：物流丢失，4：其他）
          isSendBack: '',//是否需要回寄
          pauseRemark: ''//	暂停具体原因(原因为其他)
        },
        rules: {
          backExpressNo: [
            { required: true, message: '请输入物流单号', trigger: 'blur' },
          ],
          actualOrderNo: [
            { required: true, message: '请输入实收机器订单号', trigger: 'blur' }
          ],
          actualImei: [
            { required: true, message: '请输入实收机器串号', trigger: 'blur' }
          ],
          pauseCauseId: [
            {  required: true, message: '请选择暂停收货原因', trigger: 'change' }
          ],
          isSendBack: [
            { required: true, message: '请选择是否需要回寄', trigger: 'change' }
          ],
          pauseRemark: [
            { required: true, message: '请填写具体原因', trigger: 'blur' }
          ]
        },
    }
  },
  computed: {},
  watch: {
    stopShopShow(val){
       this.ShopShow=val
    },
    stopShopdata(val){
      this.ruleForm.pauseCauseId = val.pauseCauseId
      console.log(val);
    }
  },
  created () {},
  mounted () {},
  methods: {
    rensonChange(e){
        console.log(e);
        this.ruleForm.backExpressNo=''
        this.ruleForm.pauseNote=''
        this.ruleForm.actualImei=''
        this.ruleForm.actualOrderNo=''
        this.ruleForm.isSendBack=''
        this.ruleForm.pauseRemark=''
        // 清除校验
        this.$refs['ruleForm'].clearValidate();
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.ruleForm.pauseCauseId===4 && !this.ruleForm.pauseRemark){
              return this.$message.error('请输入外部备注')
            }
            console.log(this.ruleForm);
            this.traceLogShow=true
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      stopResetForm(formName) {
        this.$refs[formName].resetFields();
        this.$emit('stopResetForm')
      },
      sureSbmitResult(){
        _api.stopPausetake({orderNo:this.stopShopdata.orderNo,...this.ruleForm}).then(res=>{
          this.$message({
          message: '操作成功！',
          type: 'success'
        });
          this.traceLogShow=false
          this.$emit('submitForm',this.ruleForm)
          this.ruleForm={
          backExpressNo: '',//回寄物流单号
          pauseNote:'',//	暂停收货备注
          actualImei:"",//实收机器串号
          actualOrderNo: '',//实收机器订单号
          pauseCauseId: '',//暂停收货原因id（1：门店发错货，2：申请追责，3：物流丢失，4：其他）
          isSendBack: '',//是否需要回寄
          pauseRemark: ''//	暂停具体原因(原因为其他)
        }
        })
      },
  }
}
</script>

<style scoped lang="scss"></style>
