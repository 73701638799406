<template>
    <div>
    <!-- 查看详情 -->
    <el-dialog title="查看详情" :visible.sync="detailShow" width="600px" @close="stopdetailclose()">
       <div style="margin-bottom: 30px;" v-loading="dialogLoading">
           <div style="margin-bottom: 15px;" >
             <span>暂停收货原因：</span><span style="color: #0981FF;">{{ paceData.pauseCauseId=='1'?'门店发错货':paceData.pauseCauseId=='2'?'申请追责':paceData.pauseCauseId=='3'?'物流丢失':'其他' }}</span>
           </div>
           <div style="margin-bottom: 15px;" v-if="paceData.pauseCauseId=='1'">
             <span>是否需要回寄：</span><span v-if="paceData.isSendBack" style="color: #0981FF;">是</span><span v-else style="color: red;">否</span>
           </div>
           <div style="margin-bottom: 15px;" v-if="paceData.isSendBack&&paceData.pauseCauseId=='1'">
             <span>回寄物流单号：</span><span style="color: #0981FF;">{{ paceData.backExpressNo }}</span>
           </div>
           <div style="margin-bottom: 15px;" v-if="!paceData.isSendBack&&paceData.pauseCauseId=='1'">
             <span>实收机器订单号：</span><span style="color: #0981FF;">{{ paceData.actualOrderNo }}</span>
           </div>
           <div style="margin-bottom: 15px;" v-if="!paceData.isSendBack&&paceData.pauseCauseId=='1'">
             <span>实收机器串号：</span><span style="color: #0981FF;">{{ paceData.actualImei }}</span>
           </div>
           <div style="margin-bottom: 15px;" v-if="paceData.pauseCauseId != 1">
             <span>外部备注：</span><span style="color: #0981FF;">{{ paceData.pauseRemark || '--'	 }}</span>
           </div>
           <div style="margin-bottom: 15px;" v-if="paceData.pauseCauseId=='2' || paceData.pauseCauseId=='3'">
             <span>内部备注：</span><span style="color: #0981FF;">{{ paceData.pauseNote || '--' }}</span>
           </div>
           <div>
             <span>操作人：</span><span style="color: #0981FF;">{{ paceData.pauser }}({{paceData.pauseTime  }})</span>
           </div>
       </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  export default {
    name: '',
    mixins: [],
    components: {},
    props: {
      stopShopdetailShow:{
        type: Boolean,
        default: false
      },
      detaildata:{
        type: Object,
        default: ()=>{}
      },
      pauseId: {
        type: String,
        default: ""
      }
    },
    data () {
      return {
        traceLogShow:false,
        detailShow:false,
        paceData:{},
        dialogLoading: false
      }
    },
    computed: {},
    watch: {
      stopShopdetailShow(val){
         this.detailShow=val
         if(val){
          this.getstopPauseinfo()
         }
      },
      detaildata(val){
        console.log(val,999);
      }
    },
    created () {
      
    },
    mounted () {},
    methods: {
        stopdetailclose() {
          this.detailShow=false
          this.$emit('stopdetailclose')
        },
        getstopPauseinfo(){
          let params = {
            orderNo:this.detaildata.orderNo
          }
          if(this.pauseId){
            params.pauseId = this.pauseId
          }
          this.dialogLoading = true
         _api.stopPauseinfo(params).then(res=>{
            this.paceData=res.data
            this.dialogLoading = false
          })
        }
    }
  }
  </script>
  
  <style scoped lang="scss"></style>
  